import React, { useState } from 'react';
import { Heading, HeadingVariant, Paragraph, Text } from '@hungryroot/ramen';
import { Accordion } from '../Accordion/Accordion';

import styles from './Faq.module.css';
import { produce } from 'immer';

const ACCORDION_SECTIONS = [
  {
    title: 'How does it work?',
    content: (
      <>
        Great question! You start by{' '}
        <a href="/limited-time/">taking a quiz and telling us about yourself</a>{' '}
        (your goals, how many people you're feeding, dietary needs, what you
        like to eat for dinner). Then, we'll create a grocery plan and fill your
        cart with suggested groceries and recipes that fit your needs. You can
        also always edit your delivery to get exactly what you’d like. Then
        comes the exciting part: we deliver your groceries and recipes straight
        to your door! Once you've tried your food, you can rate your groceries
        and recipes to let us know what you thought. We'll take note and use
        that info to get to know your tastebuds and improve your deliveries
        every week. Cool, huh?
      </>
    ),
  },
  {
    title: 'Why personalized grocery?',
    content: (
      <>
        Because eating healthy shouldn’t be stressful or time-consuming. We get
        to know your preferences and goals, then recommend healthy groceries,
        easy recipes and essential supplements for you and your family — so you
        can spend less time stressing, planning, shopping, and cooking, and more
        time doing what you love.
      </>
    ),
  },
  {
    title: 'What can I expect?',
    content: (
      <>
        It depends on how many people you’re feeding and your needs! Your
        delivery can have anywhere from 3-8 two- or four-serving recipes, plus
        as many individual grocery items you’d like—whether they’re snacks,
        sweets, breakfasts, fruit, or anything you want to add on.
      </>
    ),
  },
  {
    title: 'Can my kids eat this?',
    content: (
      <>
        Absolutely! Our sourcing and culinary teams are always looking for
        high-quality options in every aisle to make the whole family happy. That
        includes the staples (like milk and eggs), tons of easy, packable snacks
        (like fruit snacks and pretzels), and quick, crowd-pleasing recipes like
        pesto pasta.
      </>
    ),
  },
  {
    title: 'Can I skip a delivery?',
    content: (
      <>
        Absolutely! You can skip deliveries any week before they process, and
        you can pause or cancel your subscription anytime. Our goal is always to
        give you the best experience (and groceries) possible.
      </>
    ),
  },
  {
    title: 'How much do plans cost?',
    content: (
      <>
        Plans start at $69+! Your plan size is personalized based on how much
        food you need and can include anything and everything from breakfasts,
        lunches, and dinners to snacks and sweets. Don’t need as much food one
        week? You can skip a delivery or update your plan size anytime. (Fun
        fact: the average Hungryroot customer saves money each week by ditching
        takeout and reducing food waste!)
      </>
    ),
  },
];

const SUPPORT_URL =
  'https://support.hungryroot.com/?_gl=1*on92vd*_ga*MjkxMDYwMTQ5LjE2OTcwNTYwNDU.*_ga_LH4B8FYSQ2*MTcwNzUwODk2Mi4xMTEuMC4xNzA3NTA4OTY1LjU3LjAuMA..';

type Props = {
  sections?: typeof ACCORDION_SECTIONS;
};

export function Faq({ sections = ACCORDION_SECTIONS }: Props) {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  return (
    <section className={styles.container}>
      <Heading className={styles.header} variant={HeadingVariant.HeroAlt}>
        Your questions, answered
      </Heading>
      <div className={styles['accordion-container']}>
        {sections.map(({ title, content }) => {
          return (
            <Accordion
              className={styles.accordion}
              key={title}
              isExpanded={open[title] === true}
              onToggle={({ expanded }) => {
                setOpen(
                  produce((draft) => {
                    draft[title] = expanded;
                  })
                );
              }}
              title={<Heading variant={HeadingVariant.H6}>{title}</Heading>}
            >
              <Text>{content}</Text>
            </Accordion>
          );
        })}
      </div>
      <Paragraph className={styles['learn-more']}>
        Still have questions? Learn more <a href={SUPPORT_URL}>here</a>.
      </Paragraph>
    </section>
  );
}
